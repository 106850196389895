import { useCallback, useState } from "react";
import { GoogleFont } from "./interfaces";
import { useLoadFont } from "./useLoadFont";

interface Props {
  selectedFont: string;
  setSelectedFontInParentState: React.Dispatch<React.SetStateAction<string>>;
}

export const useHandledFontSelected = ({ setSelectedFontInParentState, selectedFont }: Props) => {
  const loadFont = useLoadFont();
  const [error, setError] = useState<string | null>(null);

  const handleFontSelect = useCallback(
    async (font: GoogleFont, onFontSelect?: (fontFamily: string) => void) => {
      try {
        if (font.family === selectedFont) {
          return;
        }
        await loadFont(font.family);
        setSelectedFontInParentState(font.family);
        onFontSelect?.(font.family);
      } catch {
        setError("Failed to load font");
      }
    },
    [setSelectedFontInParentState, loadFont, setError, selectedFont]
  );

  return { error, handleFontSelect };
};
