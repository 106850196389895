import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import { EventContext } from "../Context/EventContext";

export const useAddEventFonts = () => {
  const { saveEventFonts } = useContext(EventContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const eventId = user?.uid!;
  return {
    addEventFonts: async () => {
      await saveEventFonts(eventId);
    },
  };
};
