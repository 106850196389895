import styled from "styled-components";
import { Theme } from "../Theme";

export const StyledEventDetailsContainer = styled.div`
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 5%;
`;
export const StyledProgressBarContainer = styled.div`
  width: 80%;
  height: 10px;
  background-color: rgba(51, 51, 51, 0.1);
  border-radius: 50px;
  position: relative;
`;
export const StyledProgressBar = styled.div<{ percentage: number }>`
  position: absolute;
  height: 100%;
  z-index: 10;
  width: ${(props) => props.percentage}%;
  background-color: ${Theme.colors.darkBlue};
  border-radius: inherit;
  transition: width 0.2s ease-in;
`;

export const StyledDownloadTextContainer = styled.div`
  display: flex;
  align-items: "center";
  margin-bottom: 10px;
`;

export const StyledEventNameContainer = styled.div<{ cursorStyle: string }>`
  display: flex;
  align-items: center;
  cursor: ${({ cursorStyle }) => cursorStyle};
  max-width: 30vw;
`;

export const StyledConfirmationButtonContainer = styled.div`
  display: flex;
`;

export const StyledImageInput = styled.input`
  margin: auto;
  display: none;
`;
