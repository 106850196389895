export const ADD_EVENT_NAME_SUCCESS = "ADD_EVENT_NAME_SUCCESS";
export const ADD_EVENT_NAME_FAILURE = "ADD_EVENT_NAME_FAILURE";
export const ADD_EVENT_NAME_LOADING = "ADD_EVENT_NAME_LOADING";
export const FETCH_ONE_EVENT_LOADING = "FETCH_ONE_EVENT_LOADING";
export const FETCH_ONE_EVENT_SUCCESS = "FETCH_ONE_EVENT_SUCCESS";
export const FETCH_ONE_EVENT_FAILURE = "FETCH_ONE_EVENT_FAILURE";
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";
export const IMAGE_REMOVE_SUCCESS = "IMAGE_REMOVE_SUCCESS";
export const CHANGE_EVENT_FONT = "CHANGE_EVENT_FONT";
export const SAVE_EVENT_FONT_LOADING = "SAVE_EVENT_FONT_LOADING";
export const SAVE_EVENT_FONT_FAILURE = "SAVE_EVENT_FONT_FAILURE";
export const SAVE_EVENT_FONT_SUCCESS = "SAVE_EVENT_FONT_SUCCESS";

export interface IEvent {
  name?: string;
  imageUrl?: string;
  mobileImageUrl?: string;
  tabletImageUrl?: string;
  fontFamilyGuest?: TCustomEventFont;
  fontFamilyTableNumber?: TCustomEventFont;
  fontFamilyLetterHeading?: TCustomEventFont;
  fontFamilyTitle?: TCustomEventFont;
}

export type TCustomEventFont = "fontFamilyTableNumber" | "fontFamilyGuest" | "fontFamilyLetterHeading" | "fontFamilyTitle";

export type TAddEventNameSuccess = {
  type?: typeof ADD_EVENT_NAME_SUCCESS;
  payload: { eventName: string };
};
export type TChangeEventFont = {
  type?: typeof CHANGE_EVENT_FONT;
  payload: { fontKey: TCustomEventFont; font?: string };
};
export type TAddEventNameFailure = {
  type?: typeof ADD_EVENT_NAME_FAILURE;
};
export type TAddEventNameLoading = {
  type?: typeof ADD_EVENT_NAME_LOADING;
};
export type TFetchOneEventSuccess = {
  type?: typeof FETCH_ONE_EVENT_SUCCESS;
  payload: { event: IEvent | undefined };
};
export type TFetchOneEventFailure = {
  type?: typeof FETCH_ONE_EVENT_FAILURE;
};
export type TFetchOneEventLoading = {
  type?: typeof FETCH_ONE_EVENT_LOADING;
};

export type TImageUploadSuccess = {
  type: typeof IMAGE_UPLOAD_SUCCESS;
  imageUrl: string;
};

export type TImageRemoveSuccess = {
  type: typeof IMAGE_REMOVE_SUCCESS;
};

export type TSaveEventFontLoading = {
  type: typeof SAVE_EVENT_FONT_LOADING;
};
export type TSaveEventFontSuccess = {
  type: typeof SAVE_EVENT_FONT_SUCCESS;
};
export type TSaveEventFontFailure = {
  type: typeof SAVE_EVENT_FONT_FAILURE;
};

export type TAction =
  | TAddEventNameSuccess
  | TAddEventNameFailure
  | TAddEventNameLoading
  | TFetchOneEventSuccess
  | TFetchOneEventFailure
  | TFetchOneEventLoading
  | TImageUploadSuccess
  | TChangeEventFont
  | TImageRemoveSuccess
  | TSaveEventFontLoading
  | TSaveEventFontSuccess
  | TSaveEventFontFailure;

export interface IEventReducerState {
  event?: IEvent;
  isAddingEvent: boolean;
  isFetchingEvent: boolean;
  isSavingEventFont: boolean;
  saveEventFontFailure?: string;
}

export const eventReducer = (state: IEventReducerState, action: TAction): IEventReducerState => {
  switch (action.type) {
    case ADD_EVENT_NAME_SUCCESS: {
      const { eventName } = action.payload;
      return {
        ...state,
        event: { name: eventName },
        isAddingEvent: false,
      };
    }
    case ADD_EVENT_NAME_FAILURE: {
      return {
        ...state,
        isAddingEvent: false,
      };
    }
    case ADD_EVENT_NAME_LOADING: {
      return {
        ...state,
        isAddingEvent: true,
      };
    }
    case FETCH_ONE_EVENT_SUCCESS: {
      const { event } = action.payload;
      return {
        ...state,
        event,
        isFetchingEvent: false,
      };
    }
    case FETCH_ONE_EVENT_FAILURE: {
      return {
        ...state,
        isFetchingEvent: false,
      };
    }
    case FETCH_ONE_EVENT_LOADING: {
      return {
        ...state,
        isFetchingEvent: true,
      };
    }
    case IMAGE_UPLOAD_SUCCESS: {
      return {
        ...state,
        event: { ...state.event, imageUrl: action.imageUrl },
      };
    }
    case IMAGE_REMOVE_SUCCESS: {
      return {
        ...state,
        event: { ...state.event, imageUrl: undefined },
      };
    }
    case CHANGE_EVENT_FONT: {
      const { fontKey, font } = action.payload;
      return {
        ...state,
        event: { ...state.event, [fontKey]: font },
      };
    }
    case SAVE_EVENT_FONT_LOADING: {
      return {
        ...state,
        isSavingEventFont: true,
      };
    }
    case SAVE_EVENT_FONT_FAILURE: {
      return {
        ...state,
        isSavingEventFont: false,
      };
    }
    case SAVE_EVENT_FONT_SUCCESS: {
      return {
        ...state,
        isSavingEventFont: false,
      };
    }
    default: {
      return state;
    }
  }
};
