import { useEffect } from "react";
import { GoogleFont, GoogleFontsResponse } from "./interfaces";
import { useFontCache } from "../../../Context/FontCacheContext";
import { logger } from "../../../Logger";

const GOOGLE_FONTS_API = "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBwtNnpLvzBNebs_sgTVTT_dmvHEJCgLnQ";
export const DEFAULT_ITEMS_PER_PAGE = 10;

interface IProps {
  itemsPerPage?: number;
  setDisplayedFontsInParentState: React.Dispatch<React.SetStateAction<GoogleFont[]>>;
  setSelectedFontInParentState: React.Dispatch<React.SetStateAction<string>>;
  setErrorInParentState: React.Dispatch<React.SetStateAction<string | null>>;
  setLoadingInParentState: React.Dispatch<React.SetStateAction<boolean>>;
  initialFont: string;
}

export const useFetchFonts = ({
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
  setDisplayedFontsInParentState,
  initialFont,
  setErrorInParentState,
  setLoadingInParentState,
  setSelectedFontInParentState,
}: IProps) => {
  const { addGoogleFonts, allGoogleFonts } = useFontCache();
  useEffect(() => {
    if (allGoogleFonts.length) {
      return;
    }
    const fetchFonts = async (): Promise<void> => {
      try {
        const response = await fetch(GOOGLE_FONTS_API);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: GoogleFontsResponse = await response.json();
        const sortedFonts = data.items.sort((a, b) => a.family.localeCompare(b.family));
        addGoogleFonts(sortedFonts);
        setDisplayedFontsInParentState(sortedFonts.slice(0, itemsPerPage));

        if (initialFont) {
          const font = sortedFonts.find((f) => f.family === initialFont);
          if (font) {
            setSelectedFontInParentState(font.family);
          }
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "An error occurred while fetching fonts";
        setErrorInParentState(errorMessage);
        logger.error("Error fetching fonts:", error);
      } finally {
        setLoadingInParentState(false);
      }
    };

    fetchFonts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFont, itemsPerPage]);
};
