import { useEffect, useCallback, useRef } from "react";
import { GoogleFont } from "./interfaces";
import { useFontCache } from "../../../Context/FontCacheContext";
import { logger } from "../../../Logger";

interface IProps {
  displayedFonts: GoogleFont[];
  pickerId?: string;
}

export const useLoadFontPreview = ({ displayedFonts, pickerId }: IProps) => {
  const { loadedFonts, allGoogleFonts, addLoadedFont } = useFontCache();
  const observerRef = useRef<IntersectionObserver | null>(null);

  const loadFontForPreview = useCallback(
    async (font: GoogleFont): Promise<void> => {
      if (loadedFonts.has(font.family)) {
        return;
      }

      const fontUrl = `https://fonts.googleapis.com/css2?family=${font.family.replace(/\s+/g, "+")}:wght@400&display=swap`;
      const existingLink = document.querySelector(`link[href="${fontUrl}"]`);

      if (existingLink) {
        return;
      }

      const link = document.createElement("link");
      link.href = fontUrl;
      link.rel = "stylesheet";

      try {
        await new Promise((resolve, reject) => {
          link.onload = resolve;
          link.onerror = reject;
          document.head.appendChild(link);
        });
        addLoadedFont(font.family);
      } catch (error) {
        logger.error(error);
        link.remove(); // Cleanup on error
      }
    },
    [loadedFonts, addLoadedFont]
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "50px",
      threshold: 0.1,
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;

        const fontFamily = entry.target.getAttribute("data-font-family");
        if (!fontFamily) return;

        const font = allGoogleFonts.find((f) => f.family === fontFamily);
        if (font) loadFontForPreview(font);
      });
    };

    observerRef.current?.disconnect();
    observerRef.current = new IntersectionObserver(handleIntersection, options);

    const elements = document.querySelectorAll(`[data-picker-id="${pickerId}"]`);
    elements.forEach((element) => observerRef.current?.observe(element));

    return () => observerRef.current?.disconnect();
  }, [displayedFonts, allGoogleFonts, loadFontForPreview, pickerId]);
};
