import { useContext } from "react";
import { selectAlphabeticalListForDesktop, selectdownlaodLists } from "../../Selectors";
import { StyledCenteredContainer, StyledSeatingPlanPreview } from "../../Styles";
import { AlphabeticalLetter } from "./AlphabeticalLetter";
import { TableContext } from "../../Context/TableContext";
import { TableNumberAndGuests } from "./TableNumberAndGuests";
import { Info } from "../Ui/Info";
import { Theme } from "../../Theme";
import { EventContext } from "../../Context/EventContext";
import { selectFontFamilyGuest, selectFontFamilyLetterHeading, selectFontFamilyTableNumber } from "../../Selectors/eventSelectors";

interface IProps {
  autoFillSpace: string;
  isAZSelected: boolean;
  isTableNumberSelected: boolean;
}

export const ListPreview = ({ autoFillSpace, isAZSelected, isTableNumberSelected }: IProps) => {
  const { state } = useContext(TableContext);
  const { state: eventState } = useContext(EventContext);
  const fontFamilyLetterHeading = selectFontFamilyLetterHeading(eventState);
  const fontFamilyGuest = selectFontFamilyGuest(eventState);
  const fontFamilyTableNumber = selectFontFamilyTableNumber(eventState);
  const { tableNumberList } = selectdownlaodLists(state);
  const alphabeticalList = selectAlphabeticalListForDesktop(state);

  const noGuestsAssigned = alphabeticalList.length === 0;

  if (noGuestsAssigned) {
    return (
      <StyledCenteredContainer>
        <Info
          text={"There are no guests assigned to any of the tables."}
          textStyle="regular"
          iconStyle={{ width: 60, height: 60, color: Theme.colors.darkBlue, marginRight: 10 }}
          containerStyle={{}}
        />
      </StyledCenteredContainer>
    );
  }

  return (
    <StyledSeatingPlanPreview autoFillSpace={autoFillSpace}>
      {isAZSelected &&
        alphabeticalList.map((letterAndList) => {
          return (
            <AlphabeticalLetter
              letterAndList={letterAndList}
              key={letterAndList.letter}
              fontFamilyLetterHeading={fontFamilyLetterHeading}
              fontFamilyGuest={fontFamilyGuest}
              fontFamilyTableNumber={fontFamilyTableNumber}
            />
          );
        })}
      {isTableNumberSelected &&
        tableNumberList.map((tableNumberAndList) => {
          return <TableNumberAndGuests tableNumberAndList={tableNumberAndList} key={tableNumberAndList.tableNumberAsString} />;
        })}
    </StyledSeatingPlanPreview>
  );
};
