import React, { createContext, useContext, useEffect, useState } from "react";
import { GoogleFont } from "../Components/EventDetails/CustomFonts/interfaces";
import { logger } from "../Logger";
import { EventContext } from "./EventContext";
import { selectAllFonts } from "../Selectors/eventSelectors";

interface FontCacheContextType {
  loadedFonts: Set<string>;
  loadingGoogleFonts: boolean;
  allGoogleFonts: GoogleFont[];
  addLoadedFont: (fontFamily: string) => void;
  addGoogleFonts: (googleFonts: GoogleFont[]) => void;
  isFontLoaded: (fontFamily: string) => boolean;
}

const FontCacheContext = createContext<FontCacheContextType | undefined>(undefined);

export const FontCacheProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { state } = useContext(EventContext);
  const fonts = selectAllFonts(state);

  const [loadedFonts, setLoadedFonts] = useState<Set<string>>(
    new Set(["Bodoni Moda", "Crimson Text", "Edu VIC WA NT Beginner", "Marcellus", "Prata", "Poppins"])
  );
  const [allGoogleFonts, setAllGoogleFonts] = useState<GoogleFont[]>([]);
  const [loadingGoogleFonts, setLoadingGoogleFonts] = useState<boolean>(true);
  useEffect(() => {
    const loadSelectedFonts = async () => {
      const uniqueFonts = fonts.filter((item, index) => fonts.indexOf(item) === index);
      for (const font of uniqueFonts) {
        if (loadedFonts.has(font)) continue;
        const link = document.createElement("link");
        link.href = `https://fonts.googleapis.com/css2?family=${font.replace(/\s+/g, "+")}:wght@400&display=swap`;
        link.rel = "stylesheet";

        try {
          await new Promise((resolve, reject) => {
            link.onload = resolve;
            link.onerror = reject;
            document.head.appendChild(link);
          });

          await document.fonts.load(`1em "${font}"`);
          addLoadedFont(font);
        } catch (error) {
          link.remove();
          logger.error(`Error loading font ${font}:`, error);
          throw error;
        }
      }
    };
    loadSelectedFonts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fonts]);
  const addLoadedFont = (fontFamily: string) => {
    setLoadedFonts((prev) => {
      const newFonts = new Set(prev);
      newFonts.add(fontFamily);
      return newFonts;
    });
  };

  const addGoogleFonts = (googleFonts: GoogleFont[]) => {
    setAllGoogleFonts(googleFonts);
    setLoadingGoogleFonts(true);
  };

  const isFontLoaded = (fontFamily: string) => loadedFonts.has(fontFamily);

  return (
    <FontCacheContext.Provider
      value={{
        loadedFonts,
        addLoadedFont,
        isFontLoaded,
        addGoogleFonts,
        allGoogleFonts,
        loadingGoogleFonts,
      }}
    >
      {children}
    </FontCacheContext.Provider>
  );
};

export const useFontCache = () => {
  const context = useContext(FontCacheContext);
  if (context === undefined) {
    throw new Error("useFontCache must be used within a FontCacheProvider");
  }
  return context;
};
