import { useContext, useEffect, useRef, useState } from "react";
import QRCode from "qrcode";
import { AuthContext } from "../../Context/AuthContext";
import { StyledLink } from "../../Styles";
import { AiOutlineDownload } from "react-icons/ai";
import { Theme } from "../../Theme";
import { StyledDownloadTextContainer } from "../../Styles/EventDetails.styled";
import { logger } from "../../Logger";

const iconStyle = { width: 20, height: 20, cursor: "pointer", color: Theme.colors.darkBlue, marginLeft: 15 };

export const EventQRCode = () => {
  const {
    state: { user },
  } = useContext(AuthContext);
  const eventId = user?.uid!;
  const qrCodeSize = 250;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [dataUrl, setDataUrl] = useState<string | null>(null);
  const url = `${process.env.REACT_APP_DOMAIN}/seating-plan?id=${eventId}`;
  useEffect(() => {
    const generateDataURL = async () => {
      try {
        const dataUrl = await QRCode.toDataURL(url, { width: qrCodeSize });
        setDataUrl(dataUrl);
      } catch (error) {
        logger.error(error);
      }
    };
    const canvas = canvasRef.current;
    if (canvas) {
      QRCode.toCanvas(canvas, url, { width: qrCodeSize }, function (error: Error | null | undefined) {
        if (error) logger.error(error);
      });
    }

    generateDataURL();
  }, [url]);

  return (
    <>
      {dataUrl && (
        <StyledDownloadTextContainer>
          <StyledLink textStyle={"regular"} href={dataUrl} download="seating-plan-qrcode.png">
            Download QR Code
          </StyledLink>
          <AiOutlineDownload style={iconStyle} />
        </StyledDownloadTextContainer>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <canvas ref={canvasRef} style={{ border: "1px solid black" }} />
      </div>
    </>
  );
};
