import { useEffect, useRef } from "react";
import { GoogleFont } from "./interfaces";

interface IProps {
  allGoogleFonts: GoogleFont[];
  itemsPerPage: number;
  searchQuery: string;
  setDisplayedFontsInParentState: React.Dispatch<React.SetStateAction<GoogleFont[]>>;
  setPageInParentState: React.Dispatch<React.SetStateAction<number>>;
}

export const useSearchFonts = ({ allGoogleFonts, itemsPerPage, setDisplayedFontsInParentState, setPageInParentState, searchQuery }: IProps) => {
  const searchTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      const filteredFonts = allGoogleFonts.filter((font) => font.family.toLowerCase().includes(searchQuery.toLowerCase()));
      setDisplayedFontsInParentState(filteredFonts.slice(0, itemsPerPage));
      setPageInParentState(1);
    }, 300);

    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, allGoogleFonts, itemsPerPage]);
};
