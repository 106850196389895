import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import { functions } from "../Firebase";
import { PaymentStatus } from "./PaymentStatus";
import { StyledCenteredContainer, StyledSpinner } from "../Styles";
import { Theme } from "../Theme";
import { logger } from "../Logger";

export const PaymentSuccessful = () => {
  const { width, height } = useWindowSize();
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const sessionId = new URLSearchParams(location.search).get("session_id");

  useEffect(() => {
    const validatePayment = async () => {
      const validatePaymentFunction = httpsCallable(functions, "validatePayment");
      try {
        const result = (await validatePaymentFunction({ sessionId })) as any;
        setPaymentSuccessful(result.data.paymentValid);
      } catch (error) {
        logger.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (sessionId) {
      validatePayment();
    }
  }, [sessionId]);

  if (isLoading) {
    return (
      <StyledCenteredContainer>
        <StyledSpinner size="60px" color={Theme.colors.darkBlue} />
      </StyledCenteredContainer>
    );
  }
  if (paymentSuccessful) {
    return (
      <>
        <Confetti recycle={false} confettiSource={{ x: width / 2, y: height / 4, w: 100, h: 100 }} />
        <PaymentStatus success={true} />
      </>
    );
  }
  return <PaymentStatus success={false} />;
};
