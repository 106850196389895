import React, { useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { BiSearch } from "react-icons/bi";
import { IoIosArrowDown, IoMdCheckmark } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdError } from "react-icons/md";
import { FontButtonProps, FontPickerProps, FontsListStyledProps, GoogleFont, HeaderProps } from "./interfaces";
import { DEFAULT_ITEMS_PER_PAGE, useFetchFonts } from "./useFetchFonts";
import { useLoadMoreFonts } from "./useLoadMoreFonts";
import { useHandledFontSelected } from "./useHandleFontSelected";
import { useLoadFontPreview } from "./useLoadFontPreview";
import { useFontCache } from "../../../Context/FontCacheContext";
import { useSearchFonts } from "./useSearchFonts";
import { TextStyles } from "../../../Theme";

const Container = styled.div`
  width: 100%;
  max-width: 28rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  position: relative;
`;

const SearchContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const SearchIcon = styled(BiSearch)`
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
`;

const LoadingIcon = styled(AiOutlineLoading3Quarters)`
  animation: spin 1s linear infinite;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  outline: none;
  transition: all 0.2s;
  font-size: 0.875rem;
  ${TextStyles.regular}
  line-height: 1.25rem;

  &:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  }

  &::placeholder {
    color: #9ca3af;
  }
`;

const CheckIcon = styled(IoMdCheckmark)`
  color: #3b82f6;
  flex-shrink: 0;
`;

const SelectedFontDisplay = styled.span<{ $fontFamily: string }>`
  ${TextStyles.regular}
  font-family: ${(props) => props.$fontFamily};
  font-size: 0.9375rem;
  color: #1f2937;
`;

const ErrorMessage = styled.div`
  padding: 1rem;
  color: #ef4444;
  border: 1px solid #fca5a5;
  border-radius: 0.5rem;
  background-color: #fee2e2;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ErrorIcon = styled(MdError)`
  color: #ef4444;
  flex-shrink: 0;
`;

const LoadingMessage = styled.div`
  padding: 1rem;
  text-align: center;
  color: #6b7280;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const NoResultsMessage = styled.div`
  padding: 1rem;
  text-align: center;
  color: #6b7280;
`;

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const SkeletonLoader = styled.div`
  height: 1.5rem;
  width: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
  border-radius: 4px;
`;

const FontButton = styled.button<FontButtonProps>`
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  background-color: ${(props) => (props.$isSelected ? "#eef2ff" : "transparent")};
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  cursor: pointer;
  border-bottom: 1px solid #e5e7eb;
  min-height: 3.5rem;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${(props) => (props.$isSelected ? "#eef2ff" : "#f9fafb")};
  }

  &:focus {
    outline: none;
    background-color: ${(props) => (props.$isSelected ? "#eef2ff" : "#f9fafb")};
  }
`;

const FontName = styled.span<{ $fontLoaded: boolean; $fontFamily: string }>`
  font-size: 1.125rem;
  font-family: ${(props) => (props.$fontLoaded ? props.$fontFamily : "system-ui, sans-serif")};
  visibility: ${(props) => (props.$fontLoaded ? "visible" : "hidden")};
  height: 1.5rem;
  display: block;
`;

const FontNameContainer = styled.div`
  position: relative;
  flex-grow: 1;
  min-width: 0;
`;

const SkeletonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
`;

const CollapseIcon = styled(IoIosArrowDown)<{ $isOpen: boolean }>`
  transition: transform 0.2s ease;
  transform: rotate(${(props) => (props.$isOpen ? "180deg" : "0deg")});
  color: #6b7280;
`;

const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  cursor: pointer;
  user-select: none;
  background-color: white;
  margin-bottom: ${(props) => (props.$isOpen ? "0.5rem" : "0")};
  border-bottom-left-radius: ${(props) => (props.$isOpen ? "0.5rem" : "0")};
  border-bottom-right-radius: ${(props) => (props.$isOpen ? "0.5rem" : "0")};

  &:hover {
    background-color: #f9fafb;
  }
`;

const ListContainer = styled.div<{ $isOpen: boolean }>`
  overflow: hidden;
  height: ${(props) => (props.$isOpen ? "auto" : "0")};
`;

const FontsList = styled.div<FontsListStyledProps>`
  border: 1px solid #e5e7eb;
  border-radius: ${(props) => (props.$isOpen ? "0.5rem" : "0")};
  max-height: ${(props) => (props.$isOpen ? "24rem" : "0")};
  overflow-y: auto;
  background-color: white;
  opacity: ${(props) => (props.$isOpen ? "1" : "0")};
  position: absolute;
  z-index: 10;
  width: 100%;
`;

const FontPicker: React.FC<FontPickerProps> = ({
  onFontSelect,
  initialFont,
  pickerId,
  className = "",
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
  setIsOpen,
  isOpen,
}) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const { allGoogleFonts, loadedFonts } = useFontCache();
  const [displayedFonts, setDisplayedFonts] = useState<GoogleFont[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedFont, setSelectedFont] = useState<string>(initialFont);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  useFetchFonts({
    setDisplayedFontsInParentState: setDisplayedFonts,
    setErrorInParentState: setError,
    setLoadingInParentState: setLoading,
    setSelectedFontInParentState: setSelectedFont,
    initialFont,
  });
  useLoadFontPreview({
    displayedFonts,
    pickerId,
  });
  useLoadMoreFonts({
    displayedFonts,
    setDisplayedFontsInParentState: setDisplayedFonts,
    setPageInParentState: setPage,
    searchQuery,
    page,
    allGoogleFonts,
    listRef,
  });
  useSearchFonts({ allGoogleFonts, itemsPerPage, setDisplayedFontsInParentState: setDisplayedFonts, setPageInParentState: setPage, searchQuery });
  const { handleFontSelect } = useHandledFontSelected({ setSelectedFontInParentState: setSelectedFont, selectedFont });

  const renderFontButton = (font: GoogleFont) => {
    const isFontLoaded = loadedFonts.has(font.family);
    const showSkeleton = !isFontLoaded;

    return (
      <FontButton
        key={font.family}
        onClick={() => handleFontSelect(font, onFontSelect)}
        $isSelected={selectedFont === font.family}
        $fontLoaded={isFontLoaded}
        $fontFamily={font.family}
        role="option"
        aria-selected={selectedFont === font.family}
        data-font-family={font.family}
        data-picker-id={pickerId}
      >
        <FontNameContainer>
          {showSkeleton && (
            <SkeletonContainer>
              <SkeletonLoader />
            </SkeletonContainer>
          )}
          <FontName $fontLoaded={isFontLoaded} $fontFamily={font.family}>
            {font.family}
          </FontName>
        </FontNameContainer>
        {selectedFont === font.family && <CheckIcon size={20} />}
      </FontButton>
    );
  };

  if (error) {
    return (
      <ErrorMessage>
        <ErrorIcon size={20} />
        Error: {error}
      </ErrorMessage>
    );
  }

  return (
    <Container className={className} data-fontpicker="true">
      <Header onClick={setIsOpen} $isOpen={isOpen}>
        <div>{selectedFont ? <SelectedFontDisplay $fontFamily={selectedFont}>{selectedFont}</SelectedFontDisplay> : "Select a font"}</div>
        <CollapseIcon $isOpen={isOpen} size={20} />
      </Header>
      <ListContainer $isOpen={isOpen}>
        <SearchContainer>
          <SearchIcon size={20} aria-hidden="true" />
          <SearchInput
            type="text"
            placeholder="Search fonts..."
            value={searchQuery}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
            aria-label="Search fonts"
          />
        </SearchContainer>

        <FontsList role="listbox" aria-label="Font list" $isOpen={isOpen} id="1234" ref={listRef}>
          {loading ? (
            <LoadingMessage>
              <LoadingIcon size={20} />
              Loading fonts...
            </LoadingMessage>
          ) : displayedFonts.length === 0 ? (
            <NoResultsMessage>No fonts found</NoResultsMessage>
          ) : (
            <>{displayedFonts.map(renderFontButton)}</>
          )}
        </FontsList>
      </ListContainer>
    </Container>
  );
};

export default FontPicker;
