import { RefObject, useCallback, useEffect, useRef } from "react";
import { GoogleFont } from "./interfaces";
import { DEFAULT_ITEMS_PER_PAGE } from "./useFetchFonts";

interface IProps {
  listRef: RefObject<HTMLDivElement>;
  displayedFonts: GoogleFont[];
  allGoogleFonts: GoogleFont[];
  searchQuery: string;
  page: number;
  itemsPerPage?: number;
  setDisplayedFontsInParentState: React.Dispatch<React.SetStateAction<GoogleFont[]>>;
  setPageInParentState: React.Dispatch<React.SetStateAction<number>>;
}

export const useLoadMoreFonts = ({
  displayedFonts,
  allGoogleFonts,
  page,
  searchQuery,
  setDisplayedFontsInParentState,
  setPageInParentState,
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
  listRef,
}: IProps) => {
  const loadMore = useCallback(() => {
    const filteredFonts = allGoogleFonts.filter((font) => font.family.toLowerCase().includes(searchQuery.toLowerCase()));

    const nextPage = page + 1;
    const nextFonts = filteredFonts.slice(0, nextPage * itemsPerPage);

    if (nextFonts.length > displayedFonts.length) {
      setDisplayedFontsInParentState(nextFonts);
      setPageInParentState(nextPage);
    }
  }, [searchQuery, page, itemsPerPage, displayedFonts.length, allGoogleFonts, setDisplayedFontsInParentState, setPageInParentState]);

  const observerRef = useRef<IntersectionObserver | null>(null);

  const setupObserver = useCallback(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };

    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadMore();
        }
      });
    }, options);

    const sentinel = listRef?.current?.lastElementChild;

    if (sentinel) {
      observerRef.current.observe(sentinel);
    }
  }, [loadMore, listRef]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setupObserver();
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [displayedFonts, setupObserver]);

  useEffect(() => {
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);
};
