import styled from "styled-components";
import { TextStyles } from "../Theme";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export const DropdownWrapper = styled.div`
  position: relative;
  width: 200px;
  border-radius: 10px;
  cursor: pointer;
`;

export const StyledDropdownButton = styled.button<{ isOpen: boolean }>`
  position: relative;
  width: 100%;
  ${TextStyles.regular}
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.mediumGrey}`};
  border-bottom: ${({ isOpen, theme }) => (!isOpen ? `1px solid ${theme.colors.mediumGrey}` : "none")};
  border-radius: ${({ isOpen }) => (isOpen ? "10px 10px 0px 0px" : "10px 10px 10px 10px")};
  padding-right: 30px; /* Giving space for the icon. Adjust as necessary. */
  text-align: left;
  cursor: pointer;
`;

export const OptionsList = styled.ul`
  ${TextStyles.regular}
  position: absolute;
  list-style: none;
  border-radius: 0px 0px 10px 10px;
  padding: 0;
  border: 1px solid #ccc;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
`;

export const ArrowUp = styled(IoIosArrowUp)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

export const ArrowDown = styled(IoIosArrowDown)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;
export const Option = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const DropdownAndButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  height: max-content;
`;
