import { useCallback } from "react";
import { useFontCache } from "../../../Context/FontCacheContext";
import { logger } from "../../../Logger";

export const useLoadFont = () => {
  const { loadedFonts, addLoadedFont } = useFontCache();

  const loadFont = useCallback(
    async (fontFamily: string): Promise<void> => {
      if (loadedFonts.has(fontFamily)) return;

      const link = document.createElement("link");
      link.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(/\s+/g, "+")}:wght@400&display=swap`;
      link.rel = "stylesheet";

      try {
        await new Promise((resolve, reject) => {
          link.onload = resolve;
          link.onerror = reject;
          document.head.appendChild(link);
        });

        await document.fonts.load(`1em "${fontFamily}"`);
        addLoadedFont(fontFamily);
      } catch (error) {
        link.remove();
        logger.error(`Error loading font ${fontFamily}:`, error);
        throw error;
      }
    },
    [addLoadedFont, loadedFonts]
  );
  return loadFont;
};
