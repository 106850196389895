import { WorkBook, utils, WorkSheet, write } from "xlsx";
import { IDownloadLists, selectdownlaodLists } from "../../Selectors";
import { useContext, useState } from "react";
import { TableContext } from "../../Context/TableContext";
import { Dropdown } from "../Ui/Dropdown";
import { DropdownAndButtonContainer } from "../../Styles/Dropdown.styled";
import { Button } from "../Ui";
import { Theme } from "../../Theme";
import { StyledText } from "../../Styles";
import { logger } from "../../Logger";

function prepareAlphabeticalData(data: IDownloadLists): any[][] {
  const result: any[][] = [["Full Name", "Table Number"]];
  for (const alphabeticalEntry of data.alphabeticalList) {
    result.push([alphabeticalEntry.letter.toUpperCase(), ""]);
    for (const seatInfo of alphabeticalEntry.list) {
      result.push([seatInfo.fullname, seatInfo.tableNumberAsString]);
    }
    result.push([]);
  }
  return result;
}

function prepareTableNumberData(data: IDownloadLists): any[][] {
  const result: any[][] = [];
  for (const tableNumberEntry of data.tableNumberList) {
    result.push([tableNumberEntry.tableNumberAsString.toUpperCase(), ""]);
    for (const seatInfo of tableNumberEntry.list) {
      result.push([seatInfo.fullname]);
    }
    result.push([]);
  }
  return result;
}

function mergeCellsForHeaders(ws: WorkSheet, lengths: number[], startRow: number = 1): void {
  let currentRow = startRow;
  if (!ws["!merges"]) ws["!merges"] = [];
  for (const length of lengths) {
    ws["!merges"].push({ s: { r: currentRow - 1, c: 0 }, e: { r: currentRow - 1, c: 1 } });
    currentRow += length + 2;
  }
}

function convertToCSV(data: any[][]): string {
  return data.map((row) => row.join(",")).join("\n");
}

function downloadWorkbook(workbook: WorkBook, filename: string): void {
  try {
    const wbout: ArrayBuffer = write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    downloadBlob(new Blob([wbout], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), filename);
  } catch (error) {
    logger.error(error);
  }
}

function downloadCSV(csvData: string, filename: string): void {
  downloadBlob(new Blob([csvData], { type: "text/csv" }), filename);
}

function downloadBlob(blob: Blob, filename: string): void {
  const anchor = document.createElement("a");
  const url = window.URL.createObjectURL(blob);
  anchor.href = url;
  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();
  setTimeout(() => {
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url);
  }, 0);
}

function exportData(data: IDownloadLists, format: "xlsx" | "csv"): void {
  const alphabeticalData = prepareAlphabeticalData(data);
  const tableNumberData = prepareTableNumberData(data);

  if (format === "xlsx") {
    const wb: WorkBook = utils.book_new();

    const ws1 = utils.aoa_to_sheet(alphabeticalData);
    const ws2 = utils.aoa_to_sheet(tableNumberData);

    mergeCellsForHeaders(
      ws1,
      data.alphabeticalList.map((entry) => entry.list.length),
      2
    );
    mergeCellsForHeaders(
      ws2,
      data.tableNumberList.map((entry) => entry.list.length),
      1
    );

    utils.book_append_sheet(wb, ws1, "Alphabetical");
    utils.book_append_sheet(wb, ws2, "Table Number");

    downloadWorkbook(wb, "GuestList.xlsx");
  } else {
    downloadCSV(convertToCSV(alphabeticalData), "AlphabeticalList.csv");
    downloadCSV(convertToCSV(tableNumberData), "TableNumberList.csv");
  }
}

export const ExportEvent = () => {
  const { state } = useContext(TableContext);
  const [selectedFormat, setSelectedFormat] = useState<"csv" | "xlsx">("xlsx");
  const options = [
    { label: "CSV file", value: "csv" },
    { label: "Excel file", value: "xlsx" },
  ];
  const data = selectdownlaodLists(state);
  const exportToFile = () => {
    if (state.guestsNeedSaving) {
      alert("Please save your changes before downloading");
      return;
    }
    if (!selectedFormat) {
      return;
    }
    exportData(data, selectedFormat);
  };
  return (
    <>
      <StyledText textStyle="heading_2" margin="0px 0px 7px 0px" color={Theme.colors.darkBlue} fontWeight="500">
        Export seating plan
      </StyledText>
      <DropdownAndButtonContainer>
        <Dropdown
          options={options}
          selected={selectedFormat}
          onSelect={(option: "xlsx" | "csv") => setSelectedFormat(option)}
          defaultText="Select file format"
        />
        <Button
          onPress={exportToFile}
          text={"Download"}
          disabled={!selectedFormat}
          buttonStyles={{
            padding: "10px",
            width: "120px",
            color: Theme.colors.white,
            borderRadius: "10px",
            marginLeft: "10px",
            backgroundColor: Theme.colors.darkBlue,
            border: `1px solid ${Theme.colors.darkBlue}`,
          }}
        />
      </DropdownAndButtonContainer>
    </>
  );
};
