import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { Theme } from "../../Theme";
import { FlexAlignCenterContainer, StyledLink, StyledText } from "../../Styles";
import { CopyToClipboardButton } from "../Ui/CopyToClipboardButton";
import { selectEventName } from "../../Selectors/eventSelectors";
import { EventContext } from "../../Context/EventContext";

export const EventLink = () => {
  const {
    state: { user },
  } = useContext(AuthContext);
  const { state: eventState } = useContext(EventContext);
  const name = selectEventName(eventState);
  const eventId = user?.uid;
  const url = `${process.env.REACT_APP_DOMAIN}/seating-plan?id=${eventId}`;
  return (
    <div style={{ maxWidth: "30vw" }}>
      <StyledText textStyle="heading_2" color={Theme.colors.darkBlue} margin="0px 0px 7px 0px" fontWeight="500">
        Event Link
      </StyledText>
      <FlexAlignCenterContainer>
        <StyledLink textStyle="regular" href={url} target="_blank" rel="noopener noreferrer">
          {name}
        </StyledLink>
        <CopyToClipboardButton textToCopy={url} />
      </FlexAlignCenterContainer>
    </div>
  );
};
