import styled, { keyframes } from "styled-components";
import { StyledInitialsCircle } from ".";
import { breakpoints } from "./general.styled";
import { TextStyles, Theme } from "../Theme";
import { isAndroid } from "react-device-detect";

interface IOverrideStyles {
  overrideStyles?: ICustomStyles;
}

export interface IBorderStyle {
  borderRadius?: string;
  border?: string;
}

interface ICustomStyles {
  marginRight?: string;
  width?: string;
  fontColor?: string;
  textStyle?: string;
  fontFamily?: string;
}

export const SeatingPlanPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const ToggleButtonContainer = styled.div`
  ${TextStyles.regular}
  align-self: flex-end;
  margin-right: 30px;
  display: flex;
  height: 35px;
  margin-left: 30px;
  margin-top: 20px;
  width: 260px;
`;

export const StyledSeatingPlanPreview = styled.div<{ autoFillSpace: string }>`
  display: grid;
  flex: 1;
  overflow: scroll;
  padding: 35px;
  justify-content: center;
  grid-template-columns: ${({ autoFillSpace }) => `repeat(auto-fill, ${autoFillSpace})`};
`;

export const StyledSeatingPlanImageBackgound = styled.div<{ imageUrl?: string }>`
  background-image: ${({ imageUrl }) => imageUrl && `url(${imageUrl})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: ${isAndroid ? "scroll" : "fixed"};
  background-size: cover;
  position: relative;
  height: ${isAndroid ? "calc(100svh + 80px)" : "100dvh"};
  overflow: hidden;
`;

export const StyledSeatingPlanInnerContainer = styled.div<{ autoFillSpace: string }>`
  display: grid;
  margin: auto;
  width: 85%;
  justify-content: center;
  height: 100dvh;
  @media ${breakpoints.mobile} {
    width: min-content;
  }
  grid-template-columns: ${({ autoFillSpace }) => `repeat(auto-fit, minmax(min-content,${autoFillSpace}))`};
  grid-auto-rows: min-content;
`;

export const StyledEventHeading = styled.h1<{ fontFamily?: string }>`
  ${TextStyles.regular};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: 35px;
  line-height: 48px;
  grid-column: 1 / -1;
  text-align: center;
  padding: 30px 5px 15px 5px;
  font-style: italic;
`;

export const StyledAlphabeticalLetterContainer = styled.div<IBorderStyle>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 15px;
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ border }) => (border ? "15px" : "0px")};
`;

export const TableAndNameText = styled.div<IOverrideStyles>`
  ${({ overrideStyles }) => overrideStyles && TextStyles[overrideStyles.textStyle ?? "regular"]}
  text-align: left;
  color: ${({ theme, overrideStyles }) => overrideStyles?.fontColor ?? theme.colors.blackGrey};
  margin-right: ${({ overrideStyles }) => overrideStyles && overrideStyles.marginRight};
  width: ${({ overrideStyles }) => overrideStyles && overrideStyles.width};
  font-family: ${({ overrideStyles }) => overrideStyles && overrideStyles.fontFamily};
`;

export const StyledNameAndTableNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  @media (max-width: 450px) {
    width: max-content;
  }
`;

export const StyledLetterCircle = styled(StyledInitialsCircle)<{ fontFamily?: string; fontSize?: string; backgroundColor?: string; margin?: string }>`
  margin-bottom: 30px;
  font-size: ${({ fontSize }) => fontSize};
  font-family: ${({ fontFamily }) => fontFamily};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: ${({ margin }) => margin};
`;

export const StyledSeatingPlanContainer = styled.div`
  flex: 1;
`;

const loadingAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
`;

export const TextLoader = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Theme.colors.darkBlue};
  .loading {
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    text-align: center;
    span {
      display: inline-block;
      animation: ${loadingAnimation} 0.7s infinite alternate;
    }
    ${[...Array(14)]
      .map(
        (_, i) => `
      span:nth-child(${i + 2}) {
        animation-delay: ${(i + 1) * 0.1}s;
      }
    `
      )
      .join("")}
  }
`;

export const SpinnerContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableNumberContainer = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 20px;
`;

export const StyledLetterLink = styled.a`
  font-family: Prata;
  color: black;
  font-size: 20px;
  text-decoration: none;
  margin-top: 25px;
`;
