import { css } from "styled-components";
export const Theme = {
  colors: {
    darkBlue: "#225FA5",
    lightBlue: "#EAF8FF",
    mediumBlue: "#648FC0",
    lightGrey: "#F9F9F9",
    grey: "#C4C4C4",
    mediumLightGrey: "#F8FAFB",
    mediumGrey: "#B4B4B4",
    darkGrey: "#6B6B6B",
    blackGrey: "#333333",
    white: "#FFFFFF",
    offWhite: "#F9F9F9",
    red: "red",
    black: "black",
    maroon: "#BA3232",
  },
};

export const TextStyles: { [key: string]: any } = {
  regular: css`
    font-weight: 400;
    font-family: "Poppins";
    font-size: 16px;
    line-height: 20px;
  `,
  small12: css`
    font-weight: 400;
    font-family: "Poppins";
    font-size: 12px;
  `,
  heading: css`
    font-weight: 400;
    font-family: "Poppins";
    font-size: 25px;
  `,
  heading_2: css`
    font-weight: 400;
    font-family: "Poppins";
    font-size: 20px;
  `,
};
